import React from 'react';


function Close() {
  return (
    <svg className="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512">
      <path d="m256 0c-141.49 0-256 114.5-256 256 0 141.49 114.5 256 256 256 141.49 0 256-114.5 256-256 0-141.49-114.5-256-256-256zm-12.284 317.397-58.121 58.132c-6.565 6.553-15.283 10.166-24.557 10.166-19.196 0-34.734-15.526-34.734-34.734 0-9.274 3.612-17.992 10.166-24.557l58.132-58.121c6.785-6.784 6.785-17.783 0-24.568l-58.132-58.121c-6.553-6.565-10.166-15.283-10.166-24.557 0-19.196 15.526-34.734 34.734-34.734 9.274 0 17.992 3.613 24.557 10.166l58.121 58.132c6.785 6.773 17.784 6.773 24.568 0l58.121-58.132c6.565-6.553 15.283-10.166 24.557-10.166 19.196 0 34.734 15.526 34.734 34.734 0 9.274-3.612 17.992-10.166 24.557l-58.132 58.121c-6.785 6.784-6.785 17.783 0 24.568l58.132 58.121c6.553 6.565 10.166 15.283 10.166 24.557 0 19.196-15.526 34.734-34.734 34.734-9.274 0-17.992-3.613-24.557-10.166l-58.121-58.132c-6.784-6.784-17.784-6.773-24.568 0z"/>
    </svg>
  );
}

function FindSales(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800" {...props}>
      <g>
        <g id="Layer_1">
          <path fill="#ff507d" d="M396.2,0C222.2,2.1,97.2,164.4,123.8,336.4c28.1,181.9,188.1,289.8,268,458.6,3.2,6.7,12.5,6.7,15.7,0,87.8-185.5,272.3-297.4,272.3-514.8,0-155.9-127.3-282-283.6-280.1ZM399.6,476.2c-108.3,0-196.1-87.8-196.1-196.1s87.8-196.1,196.1-196.1,196.1,87.8,196.1,196.1-87.8,196.1-196.1,196.1Z"/>
          <path fill="#afb9d2" d="M497.7,196.1h-163.3l-2.4-8c-3.5-11.9-14.5-20-26.8-20h-17.6c-7.7,0-14,6.3-14,14h0c0,7.7,6.3,14,14,14h17.6l33,110.1c5.3,17.8,21.7,30,40.3,30h91.2c23.2,0,42-18.8,42-42v-84c0-7.7-6.3-14-14-14ZM483.7,294.1c0,7.7-6.3,14-14,14h-91.2c-6.2,0-11.6-4-13.4-10l-22.2-74.1h140.8v70h0Z"/>
          <g>
            <circle fill="#7f8499" cx="350.6" cy="371.2" r="21"/>
            <circle fill="#7f8499" cx="476.7" cy="371.2" r="21"/>
          </g>
          <path fill="#d23c69" d="M623.6,272.8c-3.5-111.1-91.3-204.8-202-215.7-28.5-2.8-56,0-81.7,7.1-8.7,2.4-15.7-7-10.9-14.6C348.4,19.4,371.8,1.1,397.6,0c-.5,0-1,0-1.5,0C222.2,2.1,97.2,164.4,123.8,336.4c28.2,181.9,188.1,289.8,268,458.6,1.6,3.4,4.8,5.1,8,5-1.4,0-2.8-1.7-3.5-5-23.9-111.9-63.6-197.2-91.4-294.9-2.2-7.7,5.5-14.5,12.9-11.5,25.4,10,52.9,15.6,81.9,15.6,126.2,0,228-104.3,224-231.4ZM595.7,280.1c0,108.3-87.8,196.1-196.1,196.1s-196.1-87.8-196.1-196.1,87.8-196.1,196.1-196.1,196.1,87.8,196.1,196.1Z"/>
        </g>
      </g>
    </svg>
  );
}

function BuyOnline(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 800 800" className="buy_online_icon" {...props}>
      <g>
        <g>
          <path class="cls-6" d="M514.5,257.5V95.3c-.4-14.8-12.6-26.5-27.4-26.3H165.1c-14.8-.2-27,11.5-27.4,26.3v604.9c.4,14.8,12.6,26.5,27.4,26.3h321.8c14.8.2,27-11.5,27.4-26.3v-175.3"/>
          <path class="cls-9" d="M475.1,522.7v87.7c0,2.5-2.1,4.4-4.6,4.4,0,0,0,0,0,0H194.7c-2.5,0-4.5-1.9-4.6-4.4,0,0,0,0,0,0V163.3c0-2.5,2.1-4.4,4.6-4.4,0,0,0,0,0,0h275.8c2.5,0,4.5,1.9,4.6,4.4,0,0,0,0,0,0v87.7h-147.1c-7.4-.2-13.6,5.7-13.8,13.1h0v245.5c.2,7.4,6.4,13.3,13.8,13.2,0,0,0,0,0,0h147.1Z"/>
          <circle class="cls-5" cx="326" cy="669.6" r="13.2"/>
          <line class="cls-2" x1="288.8" y1="119.5" x2="372.1" y2="119.5"/>
          <path class="cls-8" d="M470.5,158.9H194.7c-2.5,0-8.4,2-8.4,4.4v17.5h290.4v-17.5c-.8-2.7-3.4-4.5-6.2-4.4Z"/>
          <line class="cls-3" x1="372.6" y1="471.2" x2="438.4" y2="471.2"/>
          <rect class="cls-6" x="315.1" y="307.9" width="394.5" height="70.1"/>
          <path class="cls-9" d="M709.6,268.5v39.5h-394.5v-39.5c0-7.3,5.9-13.2,13.2-13.2h368.2c7.3,0,13.2,5.9,13.2,13.2h0Z"/>
          <path class="cls-9" d="M709.6,378.1v135.9c0,7.3-5.9,13.2-13.2,13.2h-368.2c-7.3,0-13.2-5.9-13.2-13.2h0v-135.9h394.5Z"/>
          <line class="cls-2" x1="450.7" y1="417.5" x2="483.6" y2="417.5"/>
          <line class="cls-2" x1="532.9" y1="417.5" x2="571.2" y2="417.5"/>
          <line class="cls-2" x1="615.1" y1="417.5" x2="653.4" y2="417.5"/>
          <path class="cls-4" d="M468.5,527.1v83.3c0,2.5-2.1,4.4-4.6,4.4,0,0,0,0,0,0H188.2c-2.5,0-4.5-1.9-4.6-4.4,0,0,0,0,0,0V163.3c0-2.5,2.1-4.4,4.6-4.4,0,0,0,0,0,0h275.7c2.5,0,4.5,1.9,4.6,4.4,0,0,0,0,0,0v92.1"/>
          <line class="cls-2" x1="374" y1="417.5" x2="406.8" y2="417.5"/>
          <line class="cls-1" x1="372.6" y1="460.3" x2="443.8" y2="460.3"/>
          <path class="cls-4" d="M521.1,259.7V97.5c-.6-15.1-13.3-26.9-28.4-26.3,0,0,0,0-.1,0H159.5c-15.1-.6-27.9,11.1-28.5,26.2,0,0,0,0,0,.1v604.9c.6,15.1,13.3,26.9,28.4,26.3,0,0,0,0,.1,0h333c15.1.6,27.9-11.1,28.5-26.2,0,0,0,0,0-.1v-175.3"/>
          <path class="cls-7" d="M334.2,514v-135.9h-21.9v135.9c0,7.3,8.6,12.1,15.9,12.1h21.9c-7.3,0-15.9-4.8-15.9-12.1Z"/>
          <path class="cls-7" d="M328.8,294.8c0-7.3,7.2-11.3,14.4-11.3l363.7-8.8v-6.2c0-7.3-3.1-16.4-10.4-16.4h-368.2c-8.4,1.3-14.9,8-15.9,16.4v38.4h16.4v-12.1Z"/>
          <rect class="cls-4" x="315.1" y="255.3" width="394.5" height="271.8" rx="13.2" ry="13.2"/>
        </g>
      </g>
    </svg>
  );
}

export {
  Close, FindSales, BuyOnline
};